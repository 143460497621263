import { ServiceProto } from 'tsrpc-proto';
import { ReqCreate, ResCreate } from './admin/PtlCreate';
import { ReqDelete, ResDelete } from './admin/PtlDelete';
import { ReqDetail, ResDetail } from './admin/PtlDetail';
import { ReqList, ResList } from './admin/PtlList';
import { ReqSearch, ResSearch } from './admin/PtlSearch';
import { ReqUpdate, ResUpdate } from './admin/PtlUpdate';
import { ReqCreate as ReqCreate_1, ResCreate as ResCreate_1 } from './advert/PtlCreate';
import { ReqDel, ResDel } from './advert/PtlDel';
import { ReqDetail as ReqDetail_1, ResDetail as ResDetail_1 } from './advert/PtlDetail';
import { ReqList as ReqList_1, ResList as ResList_1 } from './advert/PtlList';
import { ReqUpdate as ReqUpdate_1, ResUpdate as ResUpdate_1 } from './advert/PtlUpdate';
import { ReqAdd, ResAdd } from './article/PtlAdd';
import { ReqDel as ReqDel_1, ResDel as ResDel_1 } from './article/PtlDel';
import { ReqDetail as ReqDetail_2, ResDetail as ResDetail_2 } from './article/PtlDetail';
import { ReqList as ReqList_2, ResList as ResList_2 } from './article/PtlList';
import { ReqUpdate as ReqUpdate_2, ResUpdate as ResUpdate_2 } from './article/PtlUpdate';
import { ReqAdd as ReqAdd_1, ResAdd as ResAdd_1 } from './balance/PtlAdd';
import { ReqDel as ReqDel_2, ResDel as ResDel_2 } from './balance/PtlDel';
import { ReqDetail as ReqDetail_3, ResDetail as ResDetail_3 } from './balance/PtlDetail';
import { ReqList as ReqList_3, ResList as ResList_3 } from './balance/PtlList';
import { ReqUpdate as ReqUpdate_3, ResUpdate as ResUpdate_3 } from './balance/PtlUpdate';
import { ReqCreate as ReqCreate_2, ResCreate as ResCreate_2 } from './category/PtlCreate';
import { ReqDel as ReqDel_3, ResDel as ResDel_3 } from './category/PtlDel';
import { ReqDetail as ReqDetail_4, ResDetail as ResDetail_4 } from './category/PtlDetail';
import { ReqFind, ResFind } from './category/PtlFind';
import { ReqFindSubId, ResFindSubId } from './category/PtlFindSubId';
import { ReqSearch as ReqSearch_1, ResSearch as ResSearch_1 } from './category/PtlSearch';
import { ReqUpdate as ReqUpdate_4, ResUpdate as ResUpdate_4 } from './category/PtlUpdate';
import { ReqAdd as ReqAdd_2, ResAdd as ResAdd_2 } from './frag/PtlAdd';
import { ReqDel as ReqDel_4, ResDel as ResDel_4 } from './frag/PtlDel';
import { ReqDetail as ReqDetail_5, ResDetail as ResDetail_5 } from './frag/PtlDetail';
import { ReqList as ReqList_4, ResList as ResList_4 } from './frag/PtlList';
import { ReqUpdate as ReqUpdate_5, ResUpdate as ResUpdate_5 } from './frag/PtlUpdate';
import { ReqCreate as ReqCreate_3, ResCreate as ResCreate_3 } from './friendlink/PtlCreate';
import { ReqDel as ReqDel_5, ResDel as ResDel_5 } from './friendlink/PtlDel';
import { ReqDetail as ReqDetail_6, ResDetail as ResDetail_6 } from './friendlink/PtlDetail';
import { ReqList as ReqList_5, ResList as ResList_5 } from './friendlink/PtlList';
import { ReqUpdate as ReqUpdate_6, ResUpdate as ResUpdate_6 } from './friendlink/PtlUpdate';
import { ReqAdd as ReqAdd_3, ResAdd as ResAdd_3 } from './friends/PtlAdd';
import { ReqDel as ReqDel_6, ResDel as ResDel_6 } from './friends/PtlDel';
import { ReqDetail as ReqDetail_7, ResDetail as ResDetail_7 } from './friends/PtlDetail';
import { ReqList as ReqList_6, ResList as ResList_6 } from './friends/PtlList';
import { ReqUpdate as ReqUpdate_7, ResUpdate as ResUpdate_7 } from './friends/PtlUpdate';
import { ReqAdd as ReqAdd_4, ResAdd as ResAdd_4 } from './group/PtlAdd';
import { ReqDel as ReqDel_7, ResDel as ResDel_7 } from './group/PtlDel';
import { ReqDestail, ResDestail } from './group/PtlDestail';
import { ReqIndex, ResIndex } from './group/PtlIndex';
import { ReqList as ReqList_7, ResList as ResList_7 } from './group/PtlList';
import { ReqUpdate as ReqUpdate_8, ResUpdate as ResUpdate_8 } from './group/PtlUpdate';
import { ReqAdd as ReqAdd_5, ResAdd as ResAdd_5 } from './message/PtlAdd';
import { ReqDelete as ReqDelete_1, ResDelete as ResDelete_1 } from './message/PtlDelete';
import { ReqDetail as ReqDetail_8, ResDetail as ResDetail_8 } from './message/PtlDetail';
import { ReqEdit, ResEdit } from './message/PtlEdit';
import { ReqList as ReqList_8, ResList as ResList_8 } from './message/PtlList';
import { ReqAdd as ReqAdd_6, ResAdd as ResAdd_6 } from './model/PtlAdd';
import { ReqDel as ReqDel_8, ResDel as ResDel_8 } from './model/PtlDel';
import { ReqDetail as ReqDetail_9, ResDetail as ResDetail_9 } from './model/PtlDetail';
import { ReqEdit as ReqEdit_1, ResEdit as ResEdit_1 } from './model/PtlEdit';
import { ReqHasUse, ResHasUse } from './model/PtlHasUse';
import { ReqList as ReqList_9, ResList as ResList_9 } from './model/PtlList';
import { ReqAdd as ReqAdd_7, ResAdd as ResAdd_7 } from './page/PtlAdd';
import { ReqDel as ReqDel_9, ResDel as ResDel_9 } from './page/PtlDel';
import { ReqDetail as ReqDetail_10, ResDetail as ResDetail_10 } from './page/PtlDetail';
import { ReqList as ReqList_10, ResList as ResList_10 } from './page/PtlList';
import { ReqUpdate as ReqUpdate_9, ResUpdate as ResUpdate_9 } from './page/PtlUpdate';
import { ReqCaptcha, ResCaptcha } from './pub/PtlCaptcha';
import { ReqCheckCaptcha, ResCheckCaptcha } from './pub/PtlCheckCaptcha';
import { ReqLogin, ResLogin } from './pub/PtlLogin';
import { ReqUploadImage, ResUploadImage } from './pub/PtlUploadImage';
import { ReqAdd as ReqAdd_8, ResAdd as ResAdd_8 } from './shop/PtlAdd';
import { ReqDel as ReqDel_10, ResDel as ResDel_10 } from './shop/PtlDel';
import { ReqDetail as ReqDetail_11, ResDetail as ResDetail_11 } from './shop/PtlDetail';
import { ReqList as ReqList_11, ResList as ResList_11 } from './shop/PtlList';
import { ReqUpdate as ReqUpdate_10, ResUpdate as ResUpdate_10 } from './shop/PtlUpdate';
import { ReqFind as ReqFind_1, ResFind as ResFind_1 } from './site/PtlFind';
import { ReqRunEnv, ResRunEnv } from './site/PtlRunEnv';
import { ReqUpdateGame, ResUpdateGame } from './site/PtlUpdateGame';
import { ReqUpdateInfo, ResUpdateInfo } from './site/PtlUpdateInfo';
import { ReqUpdateSeo, ResUpdateSeo } from './site/PtlUpdateSeo';
import { ReqAdd as ReqAdd_9, ResAdd as ResAdd_9 } from './tag/PtlAdd';
import { ReqDel as ReqDel_11, ResDel as ResDel_11 } from './tag/PtlDel';
import { ReqDetail as ReqDetail_12, ResDetail as ResDetail_12 } from './tag/PtlDetail';
import { ReqEdit as ReqEdit_2, ResEdit as ResEdit_2 } from './tag/PtlEdit';
import { ReqHas, ResHas } from './tag/PtlHas';
import { ReqList as ReqList_12, ResList as ResList_12 } from './tag/PtlList';
import { ReqAdd as ReqAdd_10, ResAdd as ResAdd_10 } from './user/PtlAdd';
import { ReqDel as ReqDel_12, ResDel as ResDel_12 } from './user/PtlDel';
import { ReqDetail as ReqDetail_13, ResDetail as ResDetail_13 } from './user/PtlDetail';
import { ReqGroup, ResGroup } from './user/PtlGroup';
import { ReqList as ReqList_13, ResList as ResList_13 } from './user/PtlList';
import { ReqRecharge, ResRecharge } from './user/PtlRecharge';
import { ReqUpdate as ReqUpdate_11, ResUpdate as ResUpdate_11 } from './user/PtlUpdate';

export interface ServiceType {
    api: {
        "admin/Create": {
            req: ReqCreate,
            res: ResCreate
        },
        "admin/Delete": {
            req: ReqDelete,
            res: ResDelete
        },
        "admin/Detail": {
            req: ReqDetail,
            res: ResDetail
        },
        "admin/List": {
            req: ReqList,
            res: ResList
        },
        "admin/Search": {
            req: ReqSearch,
            res: ResSearch
        },
        "admin/Update": {
            req: ReqUpdate,
            res: ResUpdate
        },
        "advert/Create": {
            req: ReqCreate_1,
            res: ResCreate_1
        },
        "advert/Del": {
            req: ReqDel,
            res: ResDel
        },
        "advert/Detail": {
            req: ReqDetail_1,
            res: ResDetail_1
        },
        "advert/List": {
            req: ReqList_1,
            res: ResList_1
        },
        "advert/Update": {
            req: ReqUpdate_1,
            res: ResUpdate_1
        },
        "article/Add": {
            req: ReqAdd,
            res: ResAdd
        },
        "article/Del": {
            req: ReqDel_1,
            res: ResDel_1
        },
        "article/Detail": {
            req: ReqDetail_2,
            res: ResDetail_2
        },
        "article/List": {
            req: ReqList_2,
            res: ResList_2
        },
        "article/Update": {
            req: ReqUpdate_2,
            res: ResUpdate_2
        },
        "balance/Add": {
            req: ReqAdd_1,
            res: ResAdd_1
        },
        "balance/Del": {
            req: ReqDel_2,
            res: ResDel_2
        },
        "balance/Detail": {
            req: ReqDetail_3,
            res: ResDetail_3
        },
        "balance/List": {
            req: ReqList_3,
            res: ResList_3
        },
        "balance/Update": {
            req: ReqUpdate_3,
            res: ResUpdate_3
        },
        "category/Create": {
            req: ReqCreate_2,
            res: ResCreate_2
        },
        "category/Del": {
            req: ReqDel_3,
            res: ResDel_3
        },
        "category/Detail": {
            req: ReqDetail_4,
            res: ResDetail_4
        },
        "category/Find": {
            req: ReqFind,
            res: ResFind
        },
        "category/FindSubId": {
            req: ReqFindSubId,
            res: ResFindSubId
        },
        "category/Search": {
            req: ReqSearch_1,
            res: ResSearch_1
        },
        "category/Update": {
            req: ReqUpdate_4,
            res: ResUpdate_4
        },
        "frag/Add": {
            req: ReqAdd_2,
            res: ResAdd_2
        },
        "frag/Del": {
            req: ReqDel_4,
            res: ResDel_4
        },
        "frag/Detail": {
            req: ReqDetail_5,
            res: ResDetail_5
        },
        "frag/List": {
            req: ReqList_4,
            res: ResList_4
        },
        "frag/Update": {
            req: ReqUpdate_5,
            res: ResUpdate_5
        },
        "friendlink/Create": {
            req: ReqCreate_3,
            res: ResCreate_3
        },
        "friendlink/Del": {
            req: ReqDel_5,
            res: ResDel_5
        },
        "friendlink/Detail": {
            req: ReqDetail_6,
            res: ResDetail_6
        },
        "friendlink/List": {
            req: ReqList_5,
            res: ResList_5
        },
        "friendlink/Update": {
            req: ReqUpdate_6,
            res: ResUpdate_6
        },
        "friends/Add": {
            req: ReqAdd_3,
            res: ResAdd_3
        },
        "friends/Del": {
            req: ReqDel_6,
            res: ResDel_6
        },
        "friends/Detail": {
            req: ReqDetail_7,
            res: ResDetail_7
        },
        "friends/List": {
            req: ReqList_6,
            res: ResList_6
        },
        "friends/Update": {
            req: ReqUpdate_7,
            res: ResUpdate_7
        },
        "group/Add": {
            req: ReqAdd_4,
            res: ResAdd_4
        },
        "group/Del": {
            req: ReqDel_7,
            res: ResDel_7
        },
        "group/Destail": {
            req: ReqDestail,
            res: ResDestail
        },
        "group/Index": {
            req: ReqIndex,
            res: ResIndex
        },
        "group/List": {
            req: ReqList_7,
            res: ResList_7
        },
        "group/Update": {
            req: ReqUpdate_8,
            res: ResUpdate_8
        },
        "message/Add": {
            req: ReqAdd_5,
            res: ResAdd_5
        },
        "message/Delete": {
            req: ReqDelete_1,
            res: ResDelete_1
        },
        "message/Detail": {
            req: ReqDetail_8,
            res: ResDetail_8
        },
        "message/Edit": {
            req: ReqEdit,
            res: ResEdit
        },
        "message/List": {
            req: ReqList_8,
            res: ResList_8
        },
        "model/Add": {
            req: ReqAdd_6,
            res: ResAdd_6
        },
        "model/Del": {
            req: ReqDel_8,
            res: ResDel_8
        },
        "model/Detail": {
            req: ReqDetail_9,
            res: ResDetail_9
        },
        "model/Edit": {
            req: ReqEdit_1,
            res: ResEdit_1
        },
        "model/HasUse": {
            req: ReqHasUse,
            res: ResHasUse
        },
        "model/List": {
            req: ReqList_9,
            res: ResList_9
        },
        "page/Add": {
            req: ReqAdd_7,
            res: ResAdd_7
        },
        "page/Del": {
            req: ReqDel_9,
            res: ResDel_9
        },
        "page/Detail": {
            req: ReqDetail_10,
            res: ResDetail_10
        },
        "page/List": {
            req: ReqList_10,
            res: ResList_10
        },
        "page/Update": {
            req: ReqUpdate_9,
            res: ResUpdate_9
        },
        "pub/Captcha": {
            req: ReqCaptcha,
            res: ResCaptcha
        },
        "pub/CheckCaptcha": {
            req: ReqCheckCaptcha,
            res: ResCheckCaptcha
        },
        "pub/Login": {
            req: ReqLogin,
            res: ResLogin
        },
        "pub/UploadImage": {
            req: ReqUploadImage,
            res: ResUploadImage
        },
        "shop/Add": {
            req: ReqAdd_8,
            res: ResAdd_8
        },
        "shop/Del": {
            req: ReqDel_10,
            res: ResDel_10
        },
        "shop/Detail": {
            req: ReqDetail_11,
            res: ResDetail_11
        },
        "shop/List": {
            req: ReqList_11,
            res: ResList_11
        },
        "shop/Update": {
            req: ReqUpdate_10,
            res: ResUpdate_10
        },
        "site/Find": {
            req: ReqFind_1,
            res: ResFind_1
        },
        "site/RunEnv": {
            req: ReqRunEnv,
            res: ResRunEnv
        },
        "site/UpdateGame": {
            req: ReqUpdateGame,
            res: ResUpdateGame
        },
        "site/UpdateInfo": {
            req: ReqUpdateInfo,
            res: ResUpdateInfo
        },
        "site/UpdateSeo": {
            req: ReqUpdateSeo,
            res: ResUpdateSeo
        },
        "tag/Add": {
            req: ReqAdd_9,
            res: ResAdd_9
        },
        "tag/Del": {
            req: ReqDel_11,
            res: ResDel_11
        },
        "tag/Detail": {
            req: ReqDetail_12,
            res: ResDetail_12
        },
        "tag/Edit": {
            req: ReqEdit_2,
            res: ResEdit_2
        },
        "tag/Has": {
            req: ReqHas,
            res: ResHas
        },
        "tag/List": {
            req: ReqList_12,
            res: ResList_12
        },
        "user/Add": {
            req: ReqAdd_10,
            res: ResAdd_10
        },
        "user/Del": {
            req: ReqDel_12,
            res: ResDel_12
        },
        "user/Detail": {
            req: ReqDetail_13,
            res: ResDetail_13
        },
        "user/Group": {
            req: ReqGroup,
            res: ResGroup
        },
        "user/List": {
            req: ReqList_13,
            res: ResList_13
        },
        "user/Recharge": {
            req: ReqRecharge,
            res: ResRecharge
        },
        "user/Update": {
            req: ReqUpdate_11,
            res: ResUpdate_11
        }
    },
    msg: {

    }
}

export const serviceProto: ServiceProto<ServiceType> = {
    "version": 8,
    "services": [
        {
            "id": 0,
            "name": "admin/Create",
            "type": "api",
            "conf": {}
        },
        {
            "id": 1,
            "name": "admin/Delete",
            "type": "api",
            "conf": {}
        },
        {
            "id": 2,
            "name": "admin/Detail",
            "type": "api",
            "conf": {}
        },
        {
            "id": 3,
            "name": "admin/List",
            "type": "api",
            "conf": {}
        },
        {
            "id": 4,
            "name": "admin/Search",
            "type": "api",
            "conf": {}
        },
        {
            "id": 5,
            "name": "admin/Update",
            "type": "api",
            "conf": {}
        },
        {
            "id": 6,
            "name": "advert/Create",
            "type": "api",
            "conf": {}
        },
        {
            "id": 7,
            "name": "advert/Del",
            "type": "api",
            "conf": {}
        },
        {
            "id": 8,
            "name": "advert/Detail",
            "type": "api",
            "conf": {}
        },
        {
            "id": 9,
            "name": "advert/List",
            "type": "api",
            "conf": {}
        },
        {
            "id": 10,
            "name": "advert/Update",
            "type": "api",
            "conf": {}
        },
        {
            "id": 11,
            "name": "article/Add",
            "type": "api",
            "conf": {}
        },
        {
            "id": 12,
            "name": "article/Del",
            "type": "api",
            "conf": {}
        },
        {
            "id": 13,
            "name": "article/Detail",
            "type": "api",
            "conf": {}
        },
        {
            "id": 14,
            "name": "article/List",
            "type": "api",
            "conf": {}
        },
        {
            "id": 15,
            "name": "article/Update",
            "type": "api",
            "conf": {}
        },
        {
            "id": 16,
            "name": "balance/Add",
            "type": "api",
            "conf": {}
        },
        {
            "id": 17,
            "name": "balance/Del",
            "type": "api",
            "conf": {}
        },
        {
            "id": 18,
            "name": "balance/Detail",
            "type": "api",
            "conf": {}
        },
        {
            "id": 19,
            "name": "balance/List",
            "type": "api",
            "conf": {}
        },
        {
            "id": 20,
            "name": "balance/Update",
            "type": "api",
            "conf": {}
        },
        {
            "id": 21,
            "name": "category/Create",
            "type": "api",
            "conf": {}
        },
        {
            "id": 22,
            "name": "category/Del",
            "type": "api",
            "conf": {}
        },
        {
            "id": 23,
            "name": "category/Detail",
            "type": "api",
            "conf": {}
        },
        {
            "id": 24,
            "name": "category/Find",
            "type": "api",
            "conf": {}
        },
        {
            "id": 25,
            "name": "category/FindSubId",
            "type": "api",
            "conf": {}
        },
        {
            "id": 26,
            "name": "category/Search",
            "type": "api",
            "conf": {}
        },
        {
            "id": 27,
            "name": "category/Update",
            "type": "api",
            "conf": {}
        },
        {
            "id": 28,
            "name": "frag/Add",
            "type": "api",
            "conf": {}
        },
        {
            "id": 29,
            "name": "frag/Del",
            "type": "api",
            "conf": {}
        },
        {
            "id": 30,
            "name": "frag/Detail",
            "type": "api",
            "conf": {}
        },
        {
            "id": 31,
            "name": "frag/List",
            "type": "api",
            "conf": {}
        },
        {
            "id": 32,
            "name": "frag/Update",
            "type": "api",
            "conf": {}
        },
        {
            "id": 33,
            "name": "friendlink/Create",
            "type": "api",
            "conf": {}
        },
        {
            "id": 34,
            "name": "friendlink/Del",
            "type": "api",
            "conf": {}
        },
        {
            "id": 35,
            "name": "friendlink/Detail",
            "type": "api",
            "conf": {}
        },
        {
            "id": 36,
            "name": "friendlink/List",
            "type": "api",
            "conf": {}
        },
        {
            "id": 37,
            "name": "friendlink/Update",
            "type": "api",
            "conf": {}
        },
        {
            "id": 38,
            "name": "friends/Add",
            "type": "api",
            "conf": {}
        },
        {
            "id": 39,
            "name": "friends/Del",
            "type": "api",
            "conf": {}
        },
        {
            "id": 40,
            "name": "friends/Detail",
            "type": "api",
            "conf": {}
        },
        {
            "id": 41,
            "name": "friends/List",
            "type": "api",
            "conf": {}
        },
        {
            "id": 42,
            "name": "friends/Update",
            "type": "api",
            "conf": {}
        },
        {
            "id": 43,
            "name": "group/Add",
            "type": "api",
            "conf": {}
        },
        {
            "id": 44,
            "name": "group/Del",
            "type": "api",
            "conf": {}
        },
        {
            "id": 45,
            "name": "group/Destail",
            "type": "api",
            "conf": {}
        },
        {
            "id": 46,
            "name": "group/Index",
            "type": "api",
            "conf": {}
        },
        {
            "id": 47,
            "name": "group/List",
            "type": "api",
            "conf": {}
        },
        {
            "id": 48,
            "name": "group/Update",
            "type": "api",
            "conf": {}
        },
        {
            "id": 49,
            "name": "message/Add",
            "type": "api",
            "conf": {}
        },
        {
            "id": 50,
            "name": "message/Delete",
            "type": "api",
            "conf": {}
        },
        {
            "id": 51,
            "name": "message/Detail",
            "type": "api",
            "conf": {}
        },
        {
            "id": 52,
            "name": "message/Edit",
            "type": "api",
            "conf": {}
        },
        {
            "id": 53,
            "name": "message/List",
            "type": "api",
            "conf": {}
        },
        {
            "id": 54,
            "name": "model/Add",
            "type": "api",
            "conf": {}
        },
        {
            "id": 55,
            "name": "model/Del",
            "type": "api",
            "conf": {}
        },
        {
            "id": 56,
            "name": "model/Detail",
            "type": "api",
            "conf": {}
        },
        {
            "id": 57,
            "name": "model/Edit",
            "type": "api",
            "conf": {}
        },
        {
            "id": 58,
            "name": "model/HasUse",
            "type": "api",
            "conf": {}
        },
        {
            "id": 59,
            "name": "model/List",
            "type": "api",
            "conf": {}
        },
        {
            "id": 60,
            "name": "page/Add",
            "type": "api",
            "conf": {}
        },
        {
            "id": 61,
            "name": "page/Del",
            "type": "api",
            "conf": {}
        },
        {
            "id": 62,
            "name": "page/Detail",
            "type": "api",
            "conf": {}
        },
        {
            "id": 63,
            "name": "page/List",
            "type": "api",
            "conf": {}
        },
        {
            "id": 64,
            "name": "page/Update",
            "type": "api",
            "conf": {}
        },
        {
            "id": 65,
            "name": "pub/Captcha",
            "type": "api",
            "conf": {}
        },
        {
            "id": 66,
            "name": "pub/CheckCaptcha",
            "type": "api",
            "conf": {}
        },
        {
            "id": 67,
            "name": "pub/Login",
            "type": "api",
            "conf": {}
        },
        {
            "id": 68,
            "name": "pub/UploadImage",
            "type": "api"
        },
        {
            "id": 69,
            "name": "shop/Add",
            "type": "api",
            "conf": {}
        },
        {
            "id": 70,
            "name": "shop/Del",
            "type": "api",
            "conf": {}
        },
        {
            "id": 71,
            "name": "shop/Detail",
            "type": "api",
            "conf": {}
        },
        {
            "id": 72,
            "name": "shop/List",
            "type": "api",
            "conf": {}
        },
        {
            "id": 73,
            "name": "shop/Update",
            "type": "api",
            "conf": {}
        },
        {
            "id": 74,
            "name": "site/Find",
            "type": "api",
            "conf": {}
        },
        {
            "id": 75,
            "name": "site/RunEnv",
            "type": "api",
            "conf": {}
        },
        {
            "id": 76,
            "name": "site/UpdateGame",
            "type": "api",
            "conf": {}
        },
        {
            "id": 77,
            "name": "site/UpdateInfo",
            "type": "api",
            "conf": {}
        },
        {
            "id": 78,
            "name": "site/UpdateSeo",
            "type": "api",
            "conf": {}
        },
        {
            "id": 79,
            "name": "tag/Add",
            "type": "api",
            "conf": {}
        },
        {
            "id": 80,
            "name": "tag/Del",
            "type": "api",
            "conf": {}
        },
        {
            "id": 81,
            "name": "tag/Detail",
            "type": "api",
            "conf": {}
        },
        {
            "id": 82,
            "name": "tag/Edit",
            "type": "api",
            "conf": {}
        },
        {
            "id": 83,
            "name": "tag/Has",
            "type": "api",
            "conf": {}
        },
        {
            "id": 84,
            "name": "tag/List",
            "type": "api",
            "conf": {}
        },
        {
            "id": 85,
            "name": "user/Add",
            "type": "api",
            "conf": {}
        },
        {
            "id": 86,
            "name": "user/Del",
            "type": "api",
            "conf": {}
        },
        {
            "id": 87,
            "name": "user/Detail",
            "type": "api",
            "conf": {}
        },
        {
            "id": 91,
            "name": "user/Group",
            "type": "api",
            "conf": {}
        },
        {
            "id": 88,
            "name": "user/List",
            "type": "api",
            "conf": {}
        },
        {
            "id": 89,
            "name": "user/Recharge",
            "type": "api",
            "conf": {}
        },
        {
            "id": 90,
            "name": "user/Update",
            "type": "api",
            "conf": {}
        }
    ],
    "types": {
        "admin/PtlCreate/ReqCreate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "username",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "password",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "base/BaseRequest": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "__cookie",
                    "type": {
                        "type": "Reference",
                        "target": "base/Cookie"
                    },
                    "optional": true
                }
            ]
        },
        "base/Cookie": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "sessionId",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ],
            "indexSignature": {
                "keyType": "String",
                "type": {
                    "type": "Any"
                }
            }
        },
        "admin/PtlCreate/ResCreate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "base/BaseResponse": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "__cookie",
                    "type": {
                        "type": "Reference",
                        "target": "base/Cookie"
                    },
                    "optional": true
                }
            ]
        },
        "admin/PtlDelete/ReqDelete": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "admin/PtlDelete/ResDelete": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "admin/PtlDetail/ReqDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "admin/PtlDetail/ResDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/AdminData/AdminData"
                    }
                }
            ]
        },
        "../types/AdminData/AdminData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "username",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "status",
                    "type": {
                        "type": "Reference",
                        "target": "../types/AdminData/AdminStatus"
                    }
                },
                {
                    "id": 3,
                    "name": "createdAt",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 4,
                    "name": "updatedAt",
                    "type": {
                        "type": "Date"
                    }
                }
            ]
        },
        "../types/AdminData/AdminStatus": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": 1
                },
                {
                    "id": 1,
                    "value": 2
                }
            ]
        },
        "admin/PtlList/ReqList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                }
            ]
        },
        "admin/PtlList/ResList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/AdminData/AdminData"
                        }
                    }
                }
            ]
        },
        "admin/PtlSearch/ReqSearch": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "keyword",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                }
            ]
        },
        "admin/PtlSearch/ResSearch": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/AdminData/AdminData"
                        }
                    }
                }
            ]
        },
        "admin/PtlUpdate/ReqUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "../types/AdminData/AdminData"
                        },
                        "keys": [
                            "createdAt",
                            "updatedAt"
                        ],
                        "type": "Omit"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "password",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "admin/PtlUpdate/ResUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "advert/PtlCreate/ReqCreate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "../types/AdvertData/AdvertData"
                        },
                        "keys": [
                            "id",
                            "createdAt",
                            "updatedAt"
                        ],
                        "type": "Omit"
                    }
                }
            ]
        },
        "../types/AdvertData/AdvertData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "title",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "mark",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "imgUrl",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "link",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 5,
                    "name": "platform",
                    "type": {
                        "type": "Reference",
                        "target": "../types/AdvertData/AdvertPlatform"
                    }
                },
                {
                    "id": 6,
                    "name": "position",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 7,
                    "name": "status",
                    "type": {
                        "type": "Reference",
                        "target": "../types/AdminData/AdminStatus"
                    }
                },
                {
                    "id": 8,
                    "name": "createdAt",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 9,
                    "name": "updatedAt",
                    "type": {
                        "type": "Date"
                    }
                }
            ]
        },
        "../types/AdvertData/AdvertPlatform": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": "1"
                },
                {
                    "id": 1,
                    "value": "2"
                },
                {
                    "id": 2,
                    "value": "3"
                }
            ]
        },
        "advert/PtlCreate/ResCreate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "advert/PtlDel/ReqDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "advert/PtlDel/ResDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "advert/PtlDetail/ReqDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "advert/PtlDetail/ResDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/AdvertData/AdvertData"
                    }
                }
            ]
        },
        "advert/PtlList/ReqList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "keywords",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "advert/PtlList/ResList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/AdvertData/AdvertData"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "advert/PtlUpdate/ReqUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "../types/AdvertData/AdvertData"
                        },
                        "keys": [
                            "createdAt",
                            "updatedAt"
                        ],
                        "type": "Omit"
                    }
                }
            ]
        },
        "advert/PtlUpdate/ResUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/AdvertData/AdvertData"
                    }
                }
            ]
        },
        "article/PtlAdd/ReqAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "../types/ArticleData/ArticleData"
                        },
                        "keys": [
                            "id"
                        ],
                        "type": "Omit"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "tagIds",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Number"
                        }
                    },
                    "optional": true
                }
            ]
        },
        "../types/ArticleData/ArticleData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "category",
                    "type": {
                        "type": "Reference",
                        "target": "../types/CategoryData/CategoryData"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "categoryId",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 3,
                    "name": "sub_cids",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "title",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 5,
                    "name": "short_title",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "tags",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/TagData/TagData"
                        }
                    },
                    "optional": true
                },
                {
                    "id": 7,
                    "name": "attr",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 8,
                    "name": "seo_title",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 9,
                    "name": "seo_keywords",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 10,
                    "name": "seo_description",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 11,
                    "name": "source",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 12,
                    "name": "author",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 13,
                    "name": "description",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 14,
                    "name": "img",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 15,
                    "name": "content",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 16,
                    "name": "status",
                    "type": {
                        "type": "Reference",
                        "target": "../types/ArticleData/ArticleStatus"
                    }
                },
                {
                    "id": 17,
                    "name": "pv",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 18,
                    "name": "link",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 19,
                    "name": "createdAt",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 20,
                    "name": "updatedAt",
                    "type": {
                        "type": "Date"
                    }
                }
            ]
        },
        "../types/CategoryData/CategoryData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "pid",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "seo_title",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "seo_keywords",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "seo_description",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 5,
                    "name": "title",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "pinyin",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 7,
                    "name": "path",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 8,
                    "name": "description",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 9,
                    "name": "type",
                    "type": {
                        "type": "Reference",
                        "target": "../types/CategoryData/CategoryType"
                    }
                },
                {
                    "id": 10,
                    "name": "url",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 11,
                    "name": "sort",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 12,
                    "name": "target",
                    "type": {
                        "type": "Reference",
                        "target": "../types/CategoryData/CategoryTarget"
                    }
                },
                {
                    "id": 13,
                    "name": "status",
                    "type": {
                        "type": "Reference",
                        "target": "../types/CategoryData/CategoryStatus"
                    }
                },
                {
                    "id": 14,
                    "name": "mid",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "../types/CategoryData/CategoryType": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": "1"
                },
                {
                    "id": 1,
                    "value": "2"
                }
            ]
        },
        "../types/CategoryData/CategoryTarget": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": "1"
                },
                {
                    "id": 1,
                    "value": "2"
                }
            ]
        },
        "../types/CategoryData/CategoryStatus": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": "1"
                },
                {
                    "id": 1,
                    "value": "2"
                }
            ]
        },
        "../types/TagData/TagData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "tag_name",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "tag_path",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "articles",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/ArticleData/ArticleData"
                        }
                    },
                    "optional": true
                }
            ]
        },
        "../types/ArticleData/ArticleStatus": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": "1"
                },
                {
                    "id": 1,
                    "value": "2"
                }
            ]
        },
        "article/PtlAdd/ResAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/ArticleData/ArticleData"
                    }
                }
            ]
        },
        "article/PtlDel/ReqDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "ids",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Number"
                        }
                    }
                }
            ]
        },
        "article/PtlDel/ResDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "article/PtlDetail/ReqDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "article/PtlDetail/ResDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/ArticleData/ArticleData"
                    }
                }
            ]
        },
        "article/PtlList/ReqList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "key",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "cid",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                }
            ]
        },
        "article/PtlList/ResList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/ArticleData/ArticleData"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "article/PtlUpdate/ReqUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/ArticleData/ArticleData"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "tagIds",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Number"
                        }
                    }
                }
            ]
        },
        "article/PtlUpdate/ResUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/ArticleData/ArticleData"
                    }
                }
            ]
        },
        "balance/PtlAdd/ReqAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "../types/BalanceData/BalanceData"
                        },
                        "keys": [
                            "id"
                        ],
                        "type": "Omit"
                    }
                }
            ]
        },
        "../types/BalanceData/BalanceData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "userId",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "user",
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserData/UserData"
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "money",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 4,
                    "name": "balance",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 5,
                    "name": "type",
                    "type": {
                        "type": "Reference",
                        "target": "../types/BalanceData/BalanceType"
                    }
                },
                {
                    "id": 6,
                    "name": "memo",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 7,
                    "name": "source_id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 8,
                    "name": "ctime",
                    "type": {
                        "type": "Date"
                    }
                }
            ]
        },
        "../types/UserData/UserData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 16,
                    "name": "isGameing",
                    "type": {
                        "type": "Boolean"
                    },
                    "optional": true
                },
                {
                    "id": 14,
                    "name": "diamond",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "money",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "nickName",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "sex",
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserData/UserSex"
                    }
                },
                {
                    "id": 5,
                    "name": "password",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 6,
                    "name": "avater",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 7,
                    "name": "loginAddress",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 8,
                    "name": "isdel",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 9,
                    "name": "remarks",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 10,
                    "name": "loginTime",
                    "type": {
                        "type": "Date"
                    },
                    "optional": true
                },
                {
                    "id": 11,
                    "name": "registerTime",
                    "type": {
                        "type": "Date"
                    },
                    "optional": true
                },
                {
                    "id": 12,
                    "name": "account",
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserAccountData/UserAccountData"
                    },
                    "optional": true
                },
                {
                    "id": 13,
                    "name": "mobile",
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserMobileData/UserMobileData"
                    },
                    "optional": true
                },
                {
                    "id": 15,
                    "name": "wechat",
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserWechatData/UserWechatData"
                    },
                    "optional": true
                },
                {
                    "id": 17,
                    "name": "address",
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserAddressData/UserAddressData"
                    },
                    "optional": true
                }
            ]
        },
        "../types/UserData/UserSex": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": "0"
                },
                {
                    "id": 1,
                    "value": "1"
                },
                {
                    "id": 2,
                    "value": "2"
                }
            ]
        },
        "../types/UserAccountData/UserAccountData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "user",
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserData/UserData"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "userId",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 3,
                    "name": "account",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "ctime",
                    "type": {
                        "type": "Date"
                    }
                }
            ]
        },
        "../types/UserMobileData/UserMobileData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "user",
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserData/UserData"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "userId",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 3,
                    "name": "mobile",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "ctime",
                    "type": {
                        "type": "Date"
                    }
                }
            ]
        },
        "../types/UserWechatData/UserWechatData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "user",
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserData/UserData"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "userId",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 3,
                    "name": "unionid",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "openid",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 5,
                    "name": "nickName",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "sex",
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserData/UserSex"
                    }
                },
                {
                    "id": 7,
                    "name": "country",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 12,
                    "name": "avatarUrl",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 9,
                    "name": "city",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 13,
                    "name": "province",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 10,
                    "name": "createdAt",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 11,
                    "name": "updatedAt",
                    "type": {
                        "type": "Date"
                    }
                }
            ]
        },
        "../types/UserAddressData/UserAddressData": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserAddressData/AddressData"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "user",
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserData/UserData"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "userId",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 3,
                    "name": "createdAt",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 4,
                    "name": "updatedAt",
                    "type": {
                        "type": "Date"
                    }
                }
            ]
        },
        "../types/UserAddressData/AddressData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "nation_code",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "adcode",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "city_code",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "name",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "nation",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 5,
                    "name": "province",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "city",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 7,
                    "name": "district",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "../types/BalanceData/BalanceType": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": 1
                },
                {
                    "id": 1,
                    "value": 2
                },
                {
                    "id": 2,
                    "value": 3
                },
                {
                    "id": 3,
                    "value": 4
                },
                {
                    "id": 4,
                    "value": 5
                }
            ]
        },
        "balance/PtlAdd/ResAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "balance/PtlDel/ReqDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "ids",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Number"
                        }
                    }
                }
            ]
        },
        "balance/PtlDel/ResDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "balance/PtlDetail/ReqDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "balance/PtlDetail/ResDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/BalanceData/BalanceData"
                    }
                }
            ]
        },
        "balance/PtlList/ReqList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "key",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "type",
                    "type": {
                        "type": "Reference",
                        "target": "../types/BalanceData/BalanceType"
                    },
                    "optional": true
                },
                {
                    "id": 4,
                    "name": "userid",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                }
            ]
        },
        "balance/PtlList/ResList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/BalanceData/BalanceData"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "balance/PtlUpdate/ReqUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/BalanceData/BalanceData"
                    }
                }
            ]
        },
        "balance/PtlUpdate/ResUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "category/PtlCreate/ReqCreate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "../types/CategoryData/CategoryData"
                        },
                        "keys": [
                            "id"
                        ],
                        "type": "Omit"
                    }
                }
            ]
        },
        "category/PtlCreate/ResCreate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "category/PtlDel/ReqDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "category/PtlDel/ResDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "category/PtlDetail/ReqDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "category/PtlDetail/ResDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/CategoryData/CategoryData"
                    }
                }
            ]
        },
        "category/PtlFind/ReqFind": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "key",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "category/PtlFind/ResFind": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/CategoryData/CategoryData"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "category/PtlFindSubId/ReqFindSubId": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                }
            ]
        },
        "category/PtlFindSubId/ResFindSubId": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/CategoryData/CategoryData"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "category/PtlSearch/ReqSearch": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "key",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "category/PtlSearch/ResSearch": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/CategoryData/CategoryData"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "category/PtlUpdate/ReqUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/CategoryData/CategoryData"
                    }
                }
            ]
        },
        "category/PtlUpdate/ResUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/CategoryData/CategoryData"
                    }
                }
            ]
        },
        "frag/PtlAdd/ReqAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "../types/FragData/FragData"
                        },
                        "keys": [
                            "id",
                            "updatedAt"
                        ],
                        "type": "Omit"
                    }
                }
            ]
        },
        "../types/FragData/FragData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "title",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "mark",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "content",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "createdAt",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 5,
                    "name": "updatedAt",
                    "type": {
                        "type": "Date"
                    }
                }
            ]
        },
        "frag/PtlAdd/ResAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "frag/PtlDel/ReqDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "ids",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Number"
                        }
                    }
                }
            ]
        },
        "frag/PtlDel/ResDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "frag/PtlDetail/ReqDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "frag/PtlDetail/ResDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/FragData/FragData"
                    }
                }
            ]
        },
        "frag/PtlList/ReqList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "key",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "frag/PtlList/ResList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/FragData/FragData"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "frag/PtlUpdate/ReqUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "../types/FragData/FragData"
                        },
                        "keys": [
                            "updatedAt"
                        ],
                        "type": "Omit"
                    }
                }
            ]
        },
        "frag/PtlUpdate/ResUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/FragData/FragData"
                    }
                }
            ]
        },
        "friendlink/PtlCreate/ReqCreate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "../types/FriendlinkData/FriendlinkData"
                        },
                        "keys": [
                            "id"
                        ],
                        "type": "Omit"
                    }
                }
            ]
        },
        "../types/FriendlinkData/FriendlinkData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "title",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "link",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "sort",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 4,
                    "name": "createdAt",
                    "type": {
                        "type": "Date"
                    }
                }
            ]
        },
        "friendlink/PtlCreate/ResCreate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "friendlink/PtlDel/ReqDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "friendlink/PtlDel/ResDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "friendlink/PtlDetail/ReqDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "friendlink/PtlDetail/ResDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/FriendlinkData/FriendlinkData"
                    }
                }
            ]
        },
        "friendlink/PtlList/ReqList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                }
            ]
        },
        "friendlink/PtlList/ResList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/FriendlinkData/FriendlinkData"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "friendlink/PtlUpdate/ReqUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/FriendlinkData/FriendlinkData"
                    }
                }
            ]
        },
        "friendlink/PtlUpdate/ResUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "friends/PtlAdd/ReqAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "userId",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "friendId",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "groupId",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                }
            ]
        },
        "friends/PtlAdd/ResAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "friends/PtlDel/ReqDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "ids",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Number"
                        }
                    }
                }
            ]
        },
        "friends/PtlDel/ResDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "friends/PtlDetail/ReqDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "friends/PtlDetail/ResDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/FriendsData/FriendsData"
                    }
                }
            ]
        },
        "../types/FriendsData/FriendsData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "userId",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "user",
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserData/UserData"
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "friendId",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 4,
                    "name": "friend",
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserData/UserData"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "groupId",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 6,
                    "name": "group",
                    "type": {
                        "type": "Reference",
                        "target": "../types/FriendsData/FriendGroupsData"
                    },
                    "optional": true
                },
                {
                    "id": 7,
                    "name": "status",
                    "type": {
                        "type": "Reference",
                        "target": "../types/FriendsData/FriendsStatus"
                    }
                },
                {
                    "id": 8,
                    "name": "remark",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 9,
                    "name": "createdAt",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 10,
                    "name": "updatedAt",
                    "type": {
                        "type": "Date"
                    }
                }
            ]
        },
        "../types/FriendsData/FriendGroupsData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "userId",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "groupName",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "friends",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/FriendsData/FriendsData"
                        }
                    },
                    "optional": true
                }
            ]
        },
        "../types/FriendsData/FriendsStatus": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": "1"
                },
                {
                    "id": 1,
                    "value": "2"
                },
                {
                    "id": 2,
                    "value": "3"
                }
            ]
        },
        "friends/PtlList/ReqList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "key",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "status",
                    "type": {
                        "type": "Reference",
                        "target": "../types/FriendsData/FriendsStatus"
                    },
                    "optional": true
                },
                {
                    "id": 4,
                    "name": "groupId",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "userid",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                }
            ]
        },
        "friends/PtlList/ResList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/FriendsData/FriendsData"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "friends/PtlUpdate/ReqUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/FriendsData/FriendsData"
                    }
                }
            ]
        },
        "friends/PtlUpdate/ResUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "group/PtlAdd/ReqAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "../types/FriendsData/FriendGroupsData"
                        },
                        "keys": [
                            "id"
                        ],
                        "type": "Omit"
                    }
                }
            ]
        },
        "group/PtlAdd/ResAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "group/PtlDel/ReqDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "ids",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Number"
                        }
                    }
                }
            ]
        },
        "group/PtlDel/ResDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "group/PtlDestail/ReqDestail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "group/PtlDestail/ResDestail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/FriendsData/FriendGroupsData"
                    }
                }
            ]
        },
        "group/PtlIndex/ReqIndex": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "userid",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "group/PtlIndex/ResIndex": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/FriendsData/FriendGroupsData"
                        }
                    }
                }
            ]
        },
        "group/PtlList/ReqList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "userid",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "group/PtlList/ResList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/FriendsData/FriendGroupsData"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "group/PtlUpdate/ReqUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/FriendsData/FriendGroupsData"
                    }
                }
            ]
        },
        "group/PtlUpdate/ResUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "message/PtlAdd/ReqAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "../types/MessageData/MessageData"
                        },
                        "keys": [
                            "id",
                            "updatedAt"
                        ],
                        "type": "Omit"
                    }
                }
            ]
        },
        "../types/MessageData/MessageData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "title",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "tel",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "email",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "content",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 5,
                    "name": "createdAt",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 6,
                    "name": "updatedAt",
                    "type": {
                        "type": "Date"
                    }
                }
            ]
        },
        "message/PtlAdd/ResAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "message/PtlDelete/ReqDelete": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "message/PtlDelete/ResDelete": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "message/PtlDetail/ReqDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "message/PtlDetail/ResDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/MessageData/MessageData"
                    }
                }
            ]
        },
        "message/PtlEdit/ReqEdit": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "../types/MessageData/MessageData"
                        },
                        "keys": [
                            "updatedAt"
                        ],
                        "type": "Omit"
                    }
                }
            ]
        },
        "message/PtlEdit/ResEdit": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "message/PtlList/ReqList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                }
            ]
        },
        "message/PtlList/ResList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/MessageData/MessageData"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "model/PtlAdd/ReqAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "../types/ModelData/ModelData"
                        },
                        "keys": [
                            "id"
                        ],
                        "type": "Omit"
                    }
                }
            ]
        },
        "../types/ModelData/ModelData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "model_name",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "table_name",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "status",
                    "type": {
                        "type": "Reference",
                        "target": "../types/AdminData/AdminStatus"
                    }
                }
            ]
        },
        "model/PtlAdd/ResAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/ModelData/ModelData"
                    }
                }
            ]
        },
        "model/PtlDel/ReqDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "model/PtlDel/ResDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "model/PtlDetail/ReqDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "model/PtlDetail/ResDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/ModelData/ModelData"
                    }
                }
            ]
        },
        "model/PtlEdit/ReqEdit": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/ModelData/ModelData"
                    }
                }
            ]
        },
        "model/PtlEdit/ResEdit": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/ModelData/ModelData"
                    }
                }
            ]
        },
        "model/PtlHasUse/ReqHasUse": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "model/PtlHasUse/ResHasUse": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "model/PtlList/ReqList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                }
            ]
        },
        "model/PtlList/ResList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/ModelData/ModelData"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "page/PtlAdd/ReqAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "../types/PageData/PageData"
                        },
                        "keys": [
                            "id"
                        ],
                        "type": "Omit"
                    }
                }
            ]
        },
        "../types/PageData/PageData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "categoryId",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "category",
                    "type": {
                        "type": "Reference",
                        "target": "../types/CategoryData/CategoryData"
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "seo_title",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "seo_keywords",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 5,
                    "name": "seo_description",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "title",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 7,
                    "name": "source",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 8,
                    "name": "author",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 9,
                    "name": "content",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 10,
                    "name": "pv",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 11,
                    "name": "status",
                    "type": {
                        "type": "Reference",
                        "target": "../types/ArticleData/ArticleStatus"
                    }
                },
                {
                    "id": 12,
                    "name": "createdAt",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 13,
                    "name": "updatedAt",
                    "type": {
                        "type": "Date"
                    }
                }
            ]
        },
        "page/PtlAdd/ResAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "page/PtlDel/ReqDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "ids",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Number"
                        }
                    }
                }
            ]
        },
        "page/PtlDel/ResDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "page/PtlDetail/ReqDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "page/PtlDetail/ResDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/PageData/PageData"
                    }
                }
            ]
        },
        "page/PtlList/ReqList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "key",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "cid",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                }
            ]
        },
        "page/PtlList/ResList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/PageData/PageData"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "page/PtlUpdate/ReqUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/PageData/PageData"
                    }
                }
            ]
        },
        "page/PtlUpdate/ResUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/PageData/PageData"
                    }
                }
            ]
        },
        "pub/PtlCaptcha/ReqCaptcha": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ]
        },
        "pub/PtlCaptcha/ResCaptcha": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "imageData",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "pub/PtlCheckCaptcha/ReqCheckCaptcha": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "code",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "pub/PtlCheckCaptcha/ResCheckCaptcha": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "pub/PtlLogin/ReqLogin": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "username",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "password",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "pub/PtlLogin/ResLogin": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "status",
                    "type": {
                        "type": "Reference",
                        "target": "../types/AdminData/AdminStatus"
                    }
                },
                {
                    "id": 2,
                    "name": "username",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "token",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "pub/PtlUploadImage/ReqUploadImage": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "filename",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "file",
                    "type": {
                        "type": "Buffer",
                        "arrayType": "Uint8Array"
                    }
                }
            ]
        },
        "pub/PtlUploadImage/ResUploadImage": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "path",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "shop/PtlAdd/ReqAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "../types/ShopData/ShopData"
                        },
                        "keys": [
                            "id",
                            "utime",
                            "ctime"
                        ],
                        "type": "Omit"
                    }
                }
            ]
        },
        "../types/ShopData/ShopData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "price",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "amount",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 3,
                    "name": "mkamount",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 4,
                    "name": "give",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 5,
                    "name": "name",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "type",
                    "type": {
                        "type": "Reference",
                        "target": "../types/ShopData/ShopDataType"
                    }
                },
                {
                    "id": 7,
                    "name": "ctime",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 8,
                    "name": "utime",
                    "type": {
                        "type": "Date"
                    }
                }
            ]
        },
        "../types/ShopData/ShopDataType": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": "1"
                },
                {
                    "id": 1,
                    "value": "2"
                }
            ]
        },
        "shop/PtlAdd/ResAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "shop/PtlDel/ReqDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "ids",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Number"
                        }
                    }
                }
            ]
        },
        "shop/PtlDel/ResDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "shop/PtlDetail/ReqDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "shop/PtlDetail/ResDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/ShopData/ShopData"
                    }
                }
            ]
        },
        "shop/PtlList/ReqList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "orderBy",
                    "type": {
                        "type": "Interface",
                        "properties": [
                            {
                                "id": 0,
                                "name": "sort",
                                "type": {
                                    "type": "Keyof",
                                    "target": {
                                        "type": "Reference",
                                        "target": "../types/ShopData/ShopData"
                                    }
                                }
                            },
                            {
                                "id": 1,
                                "name": "order",
                                "type": {
                                    "type": "Reference",
                                    "target": "../types/BaseType/OrderSort"
                                },
                                "optional": true
                            }
                        ]
                    }
                },
                {
                    "id": 1,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "key",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 4,
                    "name": "type",
                    "type": {
                        "type": "Reference",
                        "target": "../types/ShopData/ShopDataType"
                    },
                    "optional": true
                }
            ]
        },
        "../types/BaseType/OrderSort": {
            "type": "Union",
            "members": [
                {
                    "id": 0,
                    "type": {
                        "type": "Literal",
                        "literal": "ASC"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Literal",
                        "literal": "DESC"
                    }
                }
            ]
        },
        "shop/PtlList/ResList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/ShopData/ShopData"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "shop/PtlUpdate/ReqUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/ShopData/ShopData"
                    }
                }
            ]
        },
        "shop/PtlUpdate/ResUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/ShopData/ShopData"
                    }
                }
            ]
        },
        "site/PtlFind/ReqFind": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ]
        },
        "site/PtlFind/ResFind": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/SiteData/SiteData"
                    }
                }
            ]
        },
        "../types/SiteData/SiteData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "name",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "domain",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "email",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "icp",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 5,
                    "name": "code",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 6,
                    "name": "title",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 7,
                    "name": "keywords",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 8,
                    "name": "description",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 17,
                    "name": "notice",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 9,
                    "name": "goldRatio",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 10,
                    "name": "giveRatio",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 11,
                    "name": "topRatio",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 12,
                    "name": "registerIn",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 13,
                    "name": "registerIn2",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 14,
                    "name": "signIn",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 15,
                    "name": "createdAt",
                    "type": {
                        "type": "Date"
                    }
                },
                {
                    "id": 16,
                    "name": "updatedAt",
                    "type": {
                        "type": "Date"
                    }
                }
            ]
        },
        "site/PtlRunEnv/ReqRunEnv": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ]
        },
        "site/PtlRunEnv/ResRunEnv": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "dirname",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "sumUserCount",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 3,
                    "name": "sumChongzhi",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 4,
                    "name": "sumZengsong",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 5,
                    "name": "sumRegCount",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 6,
                    "name": "sumLoginCount",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "site/PtlUpdateGame/ReqUpdateGame": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 6,
                    "name": "notice",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 0,
                    "name": "goldRatio",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "giveRatio",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 2,
                    "name": "topRatio",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 3,
                    "name": "registerIn",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 4,
                    "name": "registerIn2",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 5,
                    "name": "signIn",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "site/PtlUpdateGame/ResUpdateGame": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/SiteData/SiteData"
                    }
                }
            ]
        },
        "site/PtlUpdateInfo/ReqUpdateInfo": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "name",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "domain",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "email",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 3,
                    "name": "icp",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "code",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "site/PtlUpdateInfo/ResUpdateInfo": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/SiteData/SiteData"
                    }
                }
            ]
        },
        "site/PtlUpdateSeo/ReqUpdateSeo": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "title",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "keywords",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "description",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "site/PtlUpdateSeo/ResUpdateSeo": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/SiteData/SiteData"
                    }
                }
            ]
        },
        "tag/PtlAdd/ReqAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "target": {
                            "type": "Reference",
                            "target": "../types/TagData/TagData"
                        },
                        "keys": [
                            "id"
                        ],
                        "type": "Omit"
                    }
                }
            ]
        },
        "tag/PtlAdd/ResAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "tag/PtlDel/ReqDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "tag/PtlDel/ResDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "tag/PtlDetail/ReqDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "tag/PtlDetail/ResDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/TagData/TagData"
                    }
                }
            ]
        },
        "tag/PtlEdit/ReqEdit": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/TagData/TagData"
                    }
                }
            ]
        },
        "tag/PtlEdit/ResEdit": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/TagData/TagData"
                    }
                }
            ]
        },
        "tag/PtlHas/ReqHas": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "path",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "tag/PtlHas/ResHas": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "tag/PtlList/ReqList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 1,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "key",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                }
            ]
        },
        "tag/PtlList/ResList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/TagData/TagData"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "user/PtlAdd/ReqAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "account",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "nickName",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 2,
                    "name": "password",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 4,
                    "name": "diamond",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 5,
                    "name": "address",
                    "type": {
                        "type": "Interface",
                        "properties": [
                            {
                                "id": 0,
                                "name": "province",
                                "type": {
                                    "type": "String"
                                }
                            },
                            {
                                "id": 1,
                                "name": "country",
                                "type": {
                                    "type": "String"
                                }
                            },
                            {
                                "id": 2,
                                "name": "city",
                                "type": {
                                    "type": "String"
                                }
                            }
                        ]
                    },
                    "optional": true
                }
            ]
        },
        "user/PtlAdd/ResAdd": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserData/UserData"
                    }
                }
            ]
        },
        "user/PtlDel/ReqDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "ids",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Number"
                        }
                    }
                }
            ]
        },
        "user/PtlDel/ResDel": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "user/PtlDetail/ReqDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "user/PtlDetail/ResDetail": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                },
                {
                    "id": 1,
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserData/UserData"
                    }
                }
            ]
        },
        "user/PtlGroup/ReqGroup": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ]
        },
        "user/PtlGroup/ResGroup": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/UserWechatData/ProvinceTopData"
                        }
                    }
                }
            ]
        },
        "../types/UserWechatData/ProvinceTopData": {
            "type": "Interface",
            "properties": [
                {
                    "id": 0,
                    "name": "province",
                    "type": {
                        "type": "String"
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "String"
                    }
                }
            ]
        },
        "user/PtlList/ReqList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "orderBy",
                    "type": {
                        "type": "Interface",
                        "properties": [
                            {
                                "id": 0,
                                "name": "sort",
                                "type": {
                                    "type": "Keyof",
                                    "target": {
                                        "type": "Reference",
                                        "target": "../types/UserData/UserData"
                                    }
                                }
                            },
                            {
                                "id": 1,
                                "name": "order",
                                "type": {
                                    "type": "Reference",
                                    "target": "../types/BaseType/OrderSort"
                                },
                                "optional": true
                            }
                        ]
                    }
                },
                {
                    "id": 1,
                    "name": "cur",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 2,
                    "name": "pageSize",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 3,
                    "name": "key",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 4,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    },
                    "optional": true
                },
                {
                    "id": 5,
                    "name": "nickName",
                    "type": {
                        "type": "String"
                    },
                    "optional": true
                },
                {
                    "id": 6,
                    "name": "role",
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserData/UserRole"
                    },
                    "optional": true
                },
                {
                    "id": 7,
                    "name": "status",
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserData/UserStatus"
                    },
                    "optional": true
                }
            ]
        },
        "../types/UserData/UserRole": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": "1"
                },
                {
                    "id": 1,
                    "value": "2"
                }
            ]
        },
        "../types/UserData/UserStatus": {
            "type": "Enum",
            "members": [
                {
                    "id": 0,
                    "value": "1"
                },
                {
                    "id": 1,
                    "value": "2"
                }
            ]
        },
        "user/PtlList/ResList": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "list",
                    "type": {
                        "type": "Array",
                        "elementType": {
                            "type": "Reference",
                            "target": "../types/UserData/UserData"
                        }
                    }
                },
                {
                    "id": 1,
                    "name": "count",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "user/PtlRecharge/ReqRecharge": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                }
            ],
            "properties": [
                {
                    "id": 0,
                    "name": "id",
                    "type": {
                        "type": "Number"
                    }
                },
                {
                    "id": 1,
                    "name": "amount",
                    "type": {
                        "type": "Number"
                    }
                }
            ]
        },
        "user/PtlRecharge/ResRecharge": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        },
        "user/PtlUpdate/ReqUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseRequest"
                    }
                },
                {
                    "id": 3,
                    "type": {
                        "type": "Reference",
                        "target": "../types/UserData/UserData"
                    }
                }
            ]
        },
        "user/PtlUpdate/ResUpdate": {
            "type": "Interface",
            "extends": [
                {
                    "id": 0,
                    "type": {
                        "type": "Reference",
                        "target": "base/BaseResponse"
                    }
                }
            ]
        }
    }
};