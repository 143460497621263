import { HttpClient } from "tsrpc-browser";
import { enableCookie } from "./models/enableCookie";
import { serviceProto } from "./shared/adminprotocols/serviceProto";

// Create Client
export const client = new HttpClient(serviceProto, {
    // server: "http://192.168.1.121:3000",
    server: "http://123.56.184.132:3000",
    // Remove this to use binary mode (remove from the server too)
    json: true,
    logger: console,
});
// Session and Cookie
enableCookie(client);