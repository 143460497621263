import { HttpClient } from "tsrpc-browser";
import { setCookie } from "@/utils/tools";

const CookieStorageKey = '__MY_COOKIE__';
export function enableCookie(client: HttpClient<any>) {
    // Send
    client.flows.preCallApiFlow.push(v => {
        // Auto get __cookie from localStorage
        const cookieStr = localStorage.getItem(CookieStorageKey);
        v.req.__cookie = cookieStr ? JSON.parse(cookieStr) : undefined;
        return v;
    })

    // Return
    client.flows.preApiReturnFlow.push(v => {
        if (v.return.isSucc) {
            // Auto set __cookie to localStorage
            if (v.return.res.__cookie) {
                localStorage.setItem(CookieStorageKey, JSON.stringify(v.return.res.__cookie))
            }
        }else{
            if (v.return.err.code == 401) {
                setCookie("username",'');
                setCookie("token",'');
                location.href = '/#/login';
                return undefined;
            }
            // const router = useRouter();
            // console.error(v.return.err,router);
            // router.replace({path:'/login'})

        }
        return v;
    })
}